import React, { useState } from "react";
import { Icons } from "../../../../../constants/icons";
import Button from "../../../../../components/common/Button";
import "react-datepicker/dist/react-datepicker.css";
import UtcDatePicker from "../../../../../components/common/UtcDatePicker";
import { fixTimezoneOffset } from "../../../../../functions/dateConverter";
import {
  defaultDateFormat,
  OrderStatus,
  superadmin,
} from "../../../../../constants/defaults";
import moment from "moment";
const setReleaseDate = (productReleaseDate) => {
  const diff = moment(productReleaseDate).diff(moment(), "days");
  if (diff > 0) {
    productReleaseDate = moment(productReleaseDate).subtract(1, "days");
    productReleaseDate.set({ h: 23, m: 59 });
    return productReleaseDate;
  }
  return null;
};
const ManagementOrderConfirm = (props) => {
  const [validDateFrom, setValidDateFrom] = useState();
  const [validDateTo, setValidDateTo] = useState(
    props.productReleaseDate !== null
      ? fixTimezoneOffset(setReleaseDate(props.productReleaseDate))
      : ""
  );
  const [dontCreateInvoice, setDontCreateInvoice] = useState(false);

  return (
    <div className="order-confirm-modal">
      <div className="order-confirm-icon">
        <img src={Icons.maskIcon}></img>
      </div>
      <div className="order-confirm-title">
        <label>Are you sure want to aprove this order?</label>
      </div>
      <div className="order-confirm-description">
        <span>If you close this popup, your order status is not change.</span>
      </div>
      {props.userRole !== superadmin &&
      props.status === OrderStatus.SalesmanApproved ? (
        <div className="pt-3">
          <div className="d-flex flex-row align-items-center justify-content-center ps-5 py-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="isActive"
              defaultChecked={props.isSalesmanApproveCreatedInvoice}
              disabled
            />
            <label className="terms-check-label" htmlFor="isActive">
              Do not create an invoice.
            </label>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-around flex-lg-row">
            <div className="d-flex flex-column date-input-left-label align-items-center ">
              <label htmlFor="validFrom">
                Start Date <sup>UTC</sup>
              </label>
              <div className="time-info d-flex flex-row mx-4 mx-md-0 mb-0">
                <span>
                  {props.processingStartDate
                    ? moment(
                        fixTimezoneOffset(props.processingStartDate, true).utc
                      ).format(defaultDateFormat + "\tHH:mm:ss")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="d-flex flex-column date-input-right-label align-items-center ">
              <label htmlFor="validTo">
                End Date <sup>UTC</sup>
              </label>
              <div className="time-info d-flex flex-row mx-4 mx-md-0 mb-0">
                <span>
                  {props.processingEndDate
                    ? moment(
                        fixTimezoneOffset(props.processingEndDate, true).utc
                      ).format(defaultDateFormat + "\tHH:mm:ss")
                    : "-"}
                </span>
              </div>
            </div>
          </div>
          <div>
            <Button
              disabled={props.btnDisable}
              className="order-confirm-yes-button"
              bindEvent={() =>
                props.approveOrder(
                  fixTimezoneOffset(props.processingStartDate, true).utc,
                  fixTimezoneOffset(props.processingEndDate, true).utc,
                  props.isSalesmanApproveCreatedInvoice
                )
              }
              label="Approve Order"
              buttonIcon={Icons.thickIcon}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex flex-row align-items-center justify-content-center ps-5 py-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="isActive"
              defaultChecked={dontCreateInvoice}
              onChange={(ev) => setDontCreateInvoice(ev.target.checked)}
            />
            <label className="terms-check-label" htmlFor="isActive">
              Do not create an invoice.
            </label>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-around">
            <UtcDatePicker
              useMaxTime
              minDate={new Date(props.voiduPromotion.ValidFrom)}
              maxDate={
                validDateTo
                  ? new Date(validDateTo?.date).getTime() - 1000 * 60 * 30
                  : new Date(props.voiduPromotion.ValidTo)
              }
              selected={
                validDateFrom?.date >= validDateTo?.date
                  ? setValidDateTo(
                      fixTimezoneOffset(
                        new Date(validDateTo?.date).getTime() + 1000 * 60 * 30
                      )
                    )
                  : validDateFrom?.date
              }
              timeIntervals={30}
              onChange={(date) => {
                setValidDateFrom(date);
              }}
              placeholderText="Start Date"
              disableTimeFilter={false}
            />
            <UtcDatePicker
              useMaxTime
              minDate={validDateFrom?.date}
              maxDate={new Date(props.voiduPromotion.ValidTo)}
              selected={validDateTo?.date}
              timeIntervals={30}
              onChange={(date) => {
                if (validDateFrom?.date < date?.date) setValidDateTo(date);
                else {
                  setValidDateTo(
                    fixTimezoneOffset(
                      new Date(validDateFrom?.date).getTime() + 1000 * 60 * 30
                    )
                  );
                }
              }}
              placeholderText="End Date"
            />
          </div>
          <div className="d-flex justify-content-center">
            <Button
              disabled={props.btnDisable}
              className="order-confirm-yes-button"
              bindEvent={() =>
                props.approveOrder(
                  validDateFrom?.utc,
                  validDateTo?.utc,
                  dontCreateInvoice
                )
              }
              label="Approve Order"
              buttonIcon={Icons.thickIcon}
            />
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center">
        <Button
          className="order-confirm-no-button"
          bindEvent={() => props.showModal()}
          label="Go Back"
          buttonIcon={Icons.leftArrowIcon}
        />
      </div>
    </div>
  );
};

export default ManagementOrderConfirm;
