import React from "react";
import { connect } from "react-redux";
import { ORDER_LIST, REDIRECT } from "../../../constants/actionTypes";
import {
  OrderStatus,
  dateTypes,
  PartnerOrderLinkList,
  TabName,
} from "../../../constants/defaults";
import Loader from "../../../components/Loader";
import Filter from "../../../components/Filter";
import Pager from "../../../components/Pager";
import Content from "../../../components/containers/Content";
import Collapse from "../../../components/common/collapse/Collapse";
import CollapseHeader from "../../../components/common/collapse/CollapseHeader";
import CollapseBody from "../../../components/common/collapse/CollapseBody";
import OrderCollapseHeader from "./components/collapse/OrderCollapseHeader";
import OrderCollapseBody from "./components/collapse/OrderCollapseBody";
import BlankPage from "../../../components/BlankPage";
import { toast } from "react-toastify";
import { Endpoints } from "../../../constants/endpoints";
import base64 from "base-64";
import OrderTabs from "../../../components/OrderTabs";
import services from "../../../api/index";

const mapStateToProps = (state) => {
  return {
    order: state.order,
    report: state.report,
    pageSize: state.common.pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ordersListOnLoad: (payload) => dispatch({ type: ORDER_LIST, payload }),
  onRedirect: (redirectTo) => dispatch({ type: REDIRECT, redirectTo }),
});

const StatusEnum = {
  sendToApproval: `${OrderStatus.Submitted},${OrderStatus.Processing},${OrderStatus.Refused},${OrderStatus.Withdraw},${OrderStatus.SalesmanApproved}`,
  waitingMyConfirmation: `${OrderStatus.Rebidding}`,
};

class MyOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: props.match.params.filter
        ? JSON.parse(base64.decode(props.match.params.filter))
        : null,
      sortBy: null,
      showCollapse: false,
      selectedItemId: null,
      pageindex: 0,
      statusList: this.getStatusesFromTab(),
      selectedTab: undefined,
      collapseIsLoading: false,
      tabStatusCount: {},
    };
  }

  async getOrders(params) {
    this.setState({ collapseIsLoading: true });
    const {
      pageindex = this.state.pageindex,
      pagesize = this.props.pageSize,
      states = this.state.statusList,
      keywords,
      productSku,
      priceFrom,
      priceTo,
      createdAtFrom,
      createdAtTo,
      IsDelivered,
      sortBy,
      IsBilled = "",
    } = params || {};
    this.setState({ pageindex: pageindex });
    this.props.ordersListOnLoad(
      Promise.all([
        services.orders.getOrders(
          pageindex,
          pagesize,
          states,
          keywords,
          productSku,
          priceFrom,
          priceTo,
          createdAtFrom,
          createdAtTo,
          IsDelivered,
          sortBy,
          IsBilled
        ),
      ])
        .then((res) => {
          this.setState({ collapseIsLoading: false, showCollapse: false });
          if (this.state.selectedTab) {
            this.getOrderStatus();
          }
          return res[0];
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  }

  async getOrderStatus(params) {
    const { startDate = "", endDate = "" } = params || {};
    Promise.all([services.report.getOrderStatusPartner(startDate, endDate)])
      .then((res) => {
        res = res[0];

        let tabStatusCountValues = [];
        tabStatusCountValues.sendToApproval = res
          .filter((f) =>
            StatusEnum.sendToApproval.split(",").includes(f.Status)
          )
          .map((item) => item.Count)
          .reduce((a, b) => a + b, 0);

        tabStatusCountValues.waitingMyConfirmation = res
          .filter((f) =>
            StatusEnum.waitingMyConfirmation.split(",").includes(f.Status)
          )
          .map((item) => item.Count)
          .reduce((a, b) => a + b, 0);

        tabStatusCountValues.allOrders = res
          .map((item) => item.Count)
          .reduce((a, b) => a + b, 0);

        this.setState({ tabStatusCount: tabStatusCountValues });
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  }

  CollapseShow(itemId) {
    this.setState({
      showCollapse:
        itemId === this.state.selectedItemId ? !this.state.showCollapse : true,
      selectedItemId: itemId,
    });
  }

  shouldComponentUpdate() {
    if (
      this.props.match.params.tabName &&
      this.state.selectedTab === undefined
    ) {
      this.setTab();
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.tabName !== prevProps.match.params.tabName) {
      this.setTab();
    }
    if (prevProps.pageSize !== this.props.pageSize) {
      this.onFilter({ pageindex: 0 });
    }
  }

  setTab() {
    this.setState({ selectedTab: this.tabKeyControl() });
  }

  componentDidMount() {
    this.selectOrders();
  }

  selectOrders() {
    let ev = this.state.filter;
    this.getOrders({
      partnerId: ev?.PartnerId,
      keywords: ev?.Text,
      priceTo: ev?.Price?.up,
      priceFrom: ev?.Price?.down,
      createdAtFrom: ev?.DateRange?.min,
      createdAtTo: ev?.DateRange?.max,
      states:
        ev?.Status?.length !== 0 && ev !== null
          ? ev?.Status?.join(",")
          : this.getStatusesFromTab(),
      sortBy: this.state.sortBy,
      pageindex: this.state.pageindex,
    });
  }

  getStatusesFromTab() {
    return this.props?.match.params.tabName === "send"
      ? `${OrderStatus.Submitted},${OrderStatus.Processing},${OrderStatus.Refused},${OrderStatus.Withdraw}, ${OrderStatus.SalesmanApproved}`
      : this.props?.match.params.tabName === "waiting"
      ? OrderStatus.Rebidding
      : "";
  }

  async onFilter(params) {
    let {
      filter = null,
      sortBy = undefined,
      pageindex = 0,
      states = undefined,
    } = params;
    let ev = filter;
    states = states ?? this.state.statusList;
    this.setState({ statusList: states });

    if (ev) {
      pageindex = 0;
      this.setState({
        filter: ev,
      });
    } else ev = this.state.filter;
    let url = Endpoints.Partner.MyOrders.url
      .replace(":tabName", this.props?.match.params.tabName)
      .replace(":filter?", base64.encode(ev ? JSON.stringify(ev) : "0"))
      .replace(":sort?", sortBy ? sortBy : "0")
      .replace(":page?", pageindex);
    this.props.onRedirect(url);
    if (sortBy !== undefined) {
      if (sortBy === this.state.sortBy) sortBy = `-${sortBy}`;
      this.setState({ sortBy });
    } else sortBy = this.state.sortBy;
    this.getOrders({
      keywords: ev?.Text,
      priceTo: ev?.Price?.up,
      priceFrom: ev?.Price?.down,
      createdAtFrom: ev?.DateRange?.min,
      createdAtTo: ev?.DateRange?.max,
      states: states
        ? states
        : ev?.Status?.join(",")
        ? ev?.Status?.join(",")
        : "",
      IsBilled:
        ev?.InvoicedStatus !== "" && ev?.InvoicedStatus !== undefined
          ? ev?.InvoicedStatus === "1"
            ? true
            : false
          : "",
      IsDelivered:
        ev?.DeliveredStatus !== "" && ev?.DeliveredStatus !== undefined
          ? ev?.DeliveredStatus === "1"
            ? true
            : false
          : "",
      sortBy: sortBy,
      pageindex: pageindex,
    });
  }

  onChangePageIndex(pageindex) {
    let key = this.state.selectedTab;
    if (key === TabName.Partner.sendToApproval) {
      this.onFilter({
        states: StatusEnum.sendToApproval,
        pageindex: pageindex,
      });
    } else if (key === TabName.Partner.waitingMyConfirmation) {
      this.onFilter({
        states: StatusEnum.waitingMyConfirmation,
        pageindex: pageindex,
      });
    } else {
      this.onFilter({
        states: "",
        pageindex: pageindex,
      });
    }
  }

  setTabKey(key) {
    let tabName =
      key === TabName.Partner.sendToApproval
        ? "send"
        : key === TabName.Partner.waitingMyConfirmation
        ? "waiting"
        : TabName.Partner.allOrders;

    this.setState({
      statusList:
        key === TabName.Partner.waitingMyConfirmation
          ? `${OrderStatus.Submitted},${OrderStatus.Processing},${OrderStatus.Refused},${OrderStatus.Withdraw}`
          : key === TabName.Partner.waitingMyConfirmation
          ? `${OrderStatus.Rebidding}`
          : undefined,
    });

    if (this.props?.match.params.tabName === tabName) return;

    let filter = this.state.filter;
    if (filter) filter.Status = [];

    this.setState({ selectedTab: key });

    if (key === TabName.Partner.sendToApproval) {
      this.onFilter({
        filter: filter,
        states: StatusEnum.sendToApproval,
        pageindex: 0,
      });
    } else if (key === TabName.Partner.waitingMyConfirmation) {
      this.onFilter({
        filter: filter,
        states: StatusEnum.waitingMyConfirmation,
        pageindex: 0,
      });
    } else {
      this.onFilter({
        filter: filter,
        states: "",
        pageindex: 0,
      });
    }

    let url = Endpoints.Partner.MyOrders.url
      .replace(":tabName", tabName)
      .replace(":filter?", base64.encode(filter ? JSON.stringify(filter) : "0"))
      .replace(":sort?", this.state.sortBy ? this.state.sortBy : "0")
      .replace(":page?", this.state.pageindex);
    this.props.onRedirect(url);
  }

  tabKeyControl() {
    switch (this.props?.match.params.tabName) {
      case "send":
        return TabName.Partner.sendToApproval;
      case "waiting":
        return TabName.Partner.waitingMyConfirmation;
      default:
        return TabName.Partner.allOrders;
    }
  }

  render() {
    const { orderList } = this.props.order;
    return (
      <Content pageTitle="Orders">
        {orderList === undefined ? (
          <Loader />
        ) : (
          <div>
            <OrderTabs
              orderList={PartnerOrderLinkList}
              setTabKey={(_key) => this.setTabKey(_key)}
              activeKey={this.tabKeyControl()}
              tabStatusCount={this.state.tabStatusCount}
            />
            <Filter
              filter={this.state.filter}
              onFilter={(params) => {
                let { filter, sortBy } = params || {};
                this.onFilter({ filter: filter, sortBy: sortBy });
              }}
              statusList={[
                OrderStatus.Completed,
                OrderStatus.Confirmed,
                OrderStatus.Closed,
                OrderStatus.Cancelled,
                OrderStatus.Failed,
                OrderStatus.Processing,
                OrderStatus.Refused,
                OrderStatus.Refund,
                OrderStatus.Refunded,
                OrderStatus.Rejected,
                OrderStatus.Rebidding,
                OrderStatus.Revoked,
                OrderStatus.Submitted,
                OrderStatus.Withdraw,
                OrderStatus.Transferred,
              ]}
              statusVisible={
                this.state.selectedTab === TabName.Partner.allOrders
              }
              isInvoicedAvailable
              priceList={[
                { down: undefined, up: 25 },
                { down: 25, up: 50 },
                { down: 50, up: 100 },
                { down: 100, up: 300 },
                { down: 300, up: undefined },
              ]}
              dateList={[
                dateTypes.ALLTIME,
                dateTypes.THISWEEK,
                dateTypes.THISMONTH,
                dateTypes.LAST2MONTH,
              ]}
              isDeliveredAvailable
            ></Filter>
            {this.state.collapseIsLoading ? (
              <Loader />
            ) : orderList?.Items.length === 0 ? (
              <BlankPage description="There is no order." />
            ) : (
              <div>
                {orderList?.Items.map((item, index) => {
                  return (
                    <Collapse item={item} index={index} key={index}>
                      <CollapseHeader>
                        <OrderCollapseHeader
                          collapseShow={() => this.CollapseShow(item.Id)}
                          showCollapse={this.state.showCollapse}
                          selectedItemId={this.state.selectedItemId}
                          item={item}
                        />
                      </CollapseHeader>
                      <CollapseBody>
                        {item.Id === this.state.selectedItemId &&
                        this.state.showCollapse ? (
                          <OrderCollapseBody
                            item={item}
                            history={item.History}
                            refreshList={() => this.onFilter()}
                          ></OrderCollapseBody>
                        ) : (
                          <></>
                        )}
                      </CollapseBody>
                    </Collapse>
                  );
                })}
                <Pager
                  pageIndex={orderList?.PageIndex}
                  totalPages={orderList?.TotalPages}
                  totalCount={orderList?.TotalCount}
                  onChange={(pageIndex) => {
                    this.onChangePageIndex(pageIndex);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </Content>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
