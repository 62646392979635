import React from "react";
import Button from "../../../../../components/common/Button";
import { Icons } from "../../../../../constants/icons";

const ManagementOrderItemDetail = (props) => {
  return (
    <div className="order-confirm-modal">
      <div className="order-confirm-icon">
        <img src={Icons.maskIcon}></img>
      </div>
      <div className="order-item-detail-title">
        <label>Order Id</label>
        <span>{props.orderItemId}</span>
      </div>
      <div className="order-item-detail-description mx-5">
        <label>Message</label>
        <span>{props.orderItemMessage.join(", ")}</span>
      </div>
      {props.orderItemStatus === "Error" ? (
        <div className="d-flex justify-content-center">
          <Button
            className="order-confirm-remove-button"
            bindEvent={() => props.deleteOrderItem(props.orderItemId)}
            label="Remove"
            buttonIcon={Icons.minusIcon}
          />
        </div>
      ) : props.orderItemStatus === "OutOfStock" ? (
        <div className="d-flex justify-content-center">
          <Button
            className="order-confirm-retry-button"
            bindEvent={() => props.retryOrderItem(props.orderItemId)}
            label=" Retry"
            buttonIcon={Icons.circleSapphireIcon}
            iconWidth={17}
            iconHeight={17}
          />
        </div>
      ) : null}
      <div className="d-flex justify-content-center">
        <Button
          className="order-confirm-no-button"
          bindEvent={() => props.handleClose()}
          label="Go Back"
          buttonIcon={Icons.leftArrowIcon}
        />
      </div>
    </div>
  );
};

export default ManagementOrderItemDetail;
